// src/api/rooms.js
import { collection, addDoc, getDocs, getDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import {db} from './firebase';

/**
 * Adaptador para manejar las habitaciones en Firestore.
 * Proporciona funciones para interactuar con la colección de habitaciones.
 */

const roomsCollection = collection(db, 'rooms');

const RoomsAdapter = {
  /**
   * Obtener todas las habitaciones.
   * @returns {Promise<Array>} Array de habitaciones.
   */
  getAllRooms: async () => {
    try {
      const querySnapshot = await getDocs(roomsCollection);
      const rooms = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return rooms;
  
    } catch (error) {
      console.error('Error al obtener las habitaciones:', error);
      throw error;
    }
  },

  /**
   * Obtener una habitación por ID.
   * @param {string} id - ID de la habitación.
   * @returns {Promise<Object>} Objeto de la habitación.
   */
  getRoomById: async (id) => {
    try {
      const roomDocRef = doc(db, 'rooms', id);
      const roomSnapshot = await getDoc(roomDocRef);
      if (roomSnapshot.exists()) {
        return { id: roomSnapshot.id, ...roomSnapshot.data() };
      } else {
        throw new Error('Habitación no encontrada');
      }
    } catch (error) {
      console.error(`Error al obtener la habitación con ID ${id}:`, error);
      throw error;
    }
  },

  /**
   * Crear una nueva habitación.
   * @param {Object} room - Objeto de habitación.
   * @returns {Promise<Object>} Objeto de la habitación creada.
   */
  createRoom: async (room) => {
    try {
      const docRef = await addDoc(roomsCollection, room);
      console.log('Habitación creada con ID:', docRef.id);
      return { id: docRef.id, ...room };
    } catch (error) {
      console.error('Error al crear la habitación:', error);
      throw error;
    }
  },

  /**
   * Actualizar una habitación existente.
   * @param {string} id - ID de la habitación.
   * @param {Object} updatedData - Datos actualizados.
   * @returns {Promise<void>}
   */
  updateRoom: async (id, updatedData) => {
    try {
      const roomDocRef = doc(db, 'rooms', id);
      await updateDoc(roomDocRef, updatedData);
      console.log('Habitación actualizada con ID:', id);
    } catch (error) {
      console.error('Error al actualizar la habitación:', error);
      throw error;
    }
  },

  /**
   * Eliminar una habitación.
   * @param {string} id - ID de la habitación.
   * @returns {Promise<void>}
   */
  deleteRoom: async (id) => {
    try {
      const roomDocRef = doc(db, 'rooms', id);
      await deleteDoc(roomDocRef);
      console.log('Habitación eliminada con ID:', id);
    } catch (error) {
      console.error('Error al eliminar la habitación:', error);
      throw error;
    }
  },
};

export default RoomsAdapter;