import React, { useState } from 'react';
import { Box, Button, Modal, TextField, MenuItem, Typography, CircularProgress, IconButton, Snackbar } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';

const ReservationModal = ({
  open,
  onClose,
  onSave,
  rooms,
  reservation,
  handleInputChange,
}) => {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  //TODO: Combinar fecha y hora todo junto, sino al pedo
  const handleSaveClick = async () => {
    setLoading(true);
    try {
      await onSave(reservation); // Llamar a la función de guardar
      setSnackbarMessage('Reserva guardada con éxito!');
      setSnackbarSeverity('success');
      onClose();
    } catch (error) {
      setSnackbarMessage('Error al guardar la reserva.');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Actualizar la hora de Check-in y ajustar el start
  const handleCheckinTimeChange = (newValue) => {
    const newCheckinTime = newValue ? newValue.toISOString() : null;
    handleInputChange({
      target: {
        name: 'checkinTime',
        value: newCheckinTime,
      },
    });

    if (reservation.start) {
      const updatedStart = dayjs(reservation.start)
        .hour(newValue.hour())
        .minute(newValue.minute())
        .toISOString();
      handleInputChange({
        target: {
          name: 'start',
          value: updatedStart,
        },
      });
    }
  };

  // Actualizar la hora de Check-out y ajustar el end
  const handleCheckoutTimeChange = (newValue) => {
    const newCheckoutTime = newValue ? newValue.toISOString() : null;
    handleInputChange({
      target: {
        name: 'checkoutTime',
        value: newCheckoutTime,
      },
    });

    if (reservation.end) {
      const updatedEnd = dayjs(reservation.end)
        .hour(newValue.hour())
        .minute(newValue.minute())
        .toISOString();
      handleInputChange({
        target: {
          name: 'end',
          value: updatedEnd,
        },
      });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          backgroundColor: 'white',
          padding: 4,
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
          overflowY: 'auto',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 10,
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
          {reservation.id ? 'Editar Reserva' : 'Crear Nueva Reserva'}
        </Typography>

        <TextField
          select
          label="Habitación"
          name="room"
          fullWidth
          margin="normal"
          value={reservation.room}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        >
          {rooms.map((room) => (
            <MenuItem key={room.id} value={room.id}>
              {room.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Nombre del huésped"
          name="guest"
          fullWidth
          margin="normal"
          value={reservation.guest}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        />

        <TextField
          select
          label="Estado"
          name="status"
          fullWidth
          margin="normal"
          value={reservation.status}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        >
          <MenuItem value="Reservado">Reservado</MenuItem>
          <MenuItem value="Confirmado">Confirmado</MenuItem>
          <MenuItem value="Cancelado">Cancelado</MenuItem>
        </TextField>

        <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
          <Box width="48%">
            <DatePicker
              label="Check-in"
              value={reservation.start ? dayjs(reservation.start) : null}
              onChange={(newValue) => {
                handleInputChange({
                  target: {
                    name: 'start',
                    value: newValue ? newValue.toISOString() : null,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth />
              )}
            />
          </Box>
          <Box width="48%">
            <TimePicker
              label="Hora de Check-in"
              value={reservation.checkinTime ? dayjs(reservation.checkinTime) : null}
              onChange={handleCheckinTimeChange} // Usar el nuevo manejador
              renderInput={(params) => (
                <TextField {...params} fullWidth />
              )}
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
          <Box width="48%">
            <DatePicker
              label="Check-out"
              value={reservation.end ? dayjs(reservation.end) : null}
              onChange={(newValue) => {
                handleInputChange({
                  target: {
                    name: 'end',
                    value: newValue ? newValue.toISOString() : null,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth />
              )}
            />
          </Box>
          <Box width="48%">
            <TimePicker
              label="Hora de Check-out"
              value={reservation.checkoutTime ? dayjs(reservation.checkoutTime) : null}
              onChange={handleCheckoutTimeChange} // Usar el nuevo manejador
              renderInput={(params) => (
                <TextField {...params} fullWidth />
              )}
            />
          </Box>
        </Box>

        <TextField
          label="Cantidad de huéspedes"
          name="guestsCount"
          type="number"
          fullWidth
          margin="normal"
          value={reservation.guestsCount}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        />

        <TextField
          label="Comentarios"
          name="comments"
          fullWidth
          margin="normal"
          multiline
          rows={3}
          value={reservation.comments}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        />

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={loading}>
            {reservation.id ? 'Actualizar' : 'Guardar'}
          </Button>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </Box>
    </Modal>
  );
};

export default ReservationModal;