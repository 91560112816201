// src/App.jsx
import React, { useState, useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import TimelinePage from './components/TimelinePage';
import RoomManagement from './components/RoomManagement/RoomManagement';
import SignUpPage from './components/SignUp/SignUpPage';
import LoginPage from './components/Login/LoginPage';
import { getCurrentHour } from './utils/dateUtils';
import { getReservations } from './api/reservations';
import RoomsAdapter from './api/rooms';
import { Timestamp } from 'firebase/firestore'; 
import Navbar from './components/Navbar/Navbar';
import LandingPage from './pages/landingpage/LandingPage';
import { AuthProvider } from './contexts/AuthContext'; // Importar AuthProvider y useAuth
import { useAuth } from './hooks/useAuth'; // Importar AuthProvider y useAuth
import './App.css';
import { BoltLoader } from "react-awesome-loaders";

const AppContent = () => {
  const [, setCurrentDate] = useState(getCurrentHour());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentViewStart, setCurrentViewStart] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [scale, setScale] = useState('day');
  const [reservations, setReservations] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const { currentUser } = useAuth(); // Usar el contexto para obtener el usuario
  console.log('Usuario logueado:', currentUser);
 

  const convertTimestamps = (reservations) => {
    return reservations.map((reservation) => ({
      ...reservation,
      start: reservation.start instanceof Timestamp ? reservation.start.toDate() : new Date(reservation.start),
      end: reservation.end instanceof Timestamp ? reservation.end.toDate() : new Date(reservation.end),
    }));
  };

  const loadRooms = async () => {
    try {
      setLoading(true);
      const roomsData = await RoomsAdapter.getAllRooms();
      setRooms(roomsData);
    } catch (error) {
      console.error('Error al cargar habitaciones:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadReservations = async () => {
    if(currentUser == null) return;
    try {
      setLoading(true);
      const reservationsData = await getReservations(currentUser.uid);
      const convertedReservations = convertTimestamps(reservationsData);
      console.log('Reservas cargadas:', convertedReservations);
      setReservations(convertedReservations);
    } catch (error) {
      console.error('Error al cargar reservas:', error);
    } finally {
      setLoading(false);
    }
  };

  useMemo(() => {
    loadRooms();
  }, []);

  useEffect(() => {
    loadReservations();
  }, [selectedDate, scale, currentViewStart, currentUser]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(getCurrentHour());
    }, 120000);
    return () => clearInterval(interval);
  }, []);

  const getOccupiedRoomsCount = (day) => {
    let count = 0;
    reservations.forEach((reservation) => {
      const bookingStartDate = new Date(reservation.start);
      const bookingEndDate = new Date(reservation.end);
      if (day >= bookingStartDate && day <= bookingEndDate) {
        count += 1;
      }
    });
    return count;
  };

  const handleDateClick = (day) => {
    setSelectedDate(day);
    setCurrentViewStart(day);
    setSelectedMonth(day.getMonth());
    setSelectedYear(day.getFullYear());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        {currentUser ? ( 
          loading ? (
            <Grid
              container
              spacing={2}
              sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f5f5f5',
                overflow: 'hidden',
                margin: 'auto',
              }}
            >
              <BoltLoader
                className={"loaderbolt"}
                boltColor={"#6366F1"}
                backgroundBlurColor={"#E0E7FF"}
  
              />
            </Grid>
          ) :
          <>
            <Navbar />
            <Grid
              container
              spacing={2}
              sx={{
                height: '100vh',
                padding: '20px',
                backgroundColor: '#f5f5f5',
                overflow: 'hidden',
                marginLeft: '2%',
              }}
            >
              <Routes>
                <Route path="/login" element={<Navigate to="/timeline" />} />
                <Route path="/timeline" element={
                  <TimelinePage
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedMonth={selectedMonth}
                    setSelectedMonth={setSelectedMonth}
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                    handleDateClick={handleDateClick}
                    getOccupiedRoomsCount={getOccupiedRoomsCount}
                    rooms={rooms}
                    bookings={reservations}
                  />
                } />
                <Route path="/rooms" element={<RoomManagement rooms={rooms} setRooms={setRooms} />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
            </Grid>
          </>


              
        ) : (
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </Router>
    </LocalizationProvider>
  );
};

const App = () => (
  <AuthProvider>
    <AppContent />
  </AuthProvider>
);

export default App;