// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Ajusta esto según tu paleta
      light: '#2196f3', // Ajusta esto según tu paleta
      dark: '#115293', // Ajusta esto según tu paleta
    },
    secondary: {
      main: '#ff4081', // Ajusta esto según tu paleta
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#757575',
    },
    error: {
      main: '#f44336',
    },
    warning: {
      main: '#ff9800',
    },
    info: {
      main: '#2196f3',
    },
    success: {
      main: '#4caf50',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#1976d2',
          color: 'white',
        },
      },
    },
  },
});

export default theme;