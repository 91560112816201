import React, { useState, useEffect, useRef } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Drawer from '@mui/material/Drawer'
import Link from '@mui/material/Link'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import backgroundImage from '../../assets/landing.png';
import hotelImg from '../../assets/hotel.png';
import img2 from '../../assets/male-mechanic.png';
import img3 from '../../assets/3.png';
import waitingImg from '../../assets/waiting-man.png';
import notificationImg from '../../assets/noti.png';
import logo from '../../assets/e-reservas-logo.png';
import WhatsAppQR from '../../assets/whatsapp-qr.png';

import { Menu as MenuIcon, Facebook, Twitter, Instagram, User, Globe } from 'lucide-react'

const theme = createTheme({
  palette: {
    primary: { main: '#0ea5e9' },
    secondary: { main: '#f59e0b' },
    background: { default: '#ffffff', paper: '#f9fafb' },
  },
  typography: {
    fontFamily: "'Lato', sans-serif",
    h1: { fontSize: '2rem', fontWeight: 300 },
    h2: { fontSize: '2.25rem', fontWeight: 300 },
    h3: { fontSize: '1.5rem', fontWeight: 400 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '50px',
        },
      },
    },
  },
})

const languages = [
  { code: 'es', name: 'Español', flag: '🇪🇸' },
  { code: 'en', name: 'English', flag: '🇬🇧' },
  { code: 'fr', name: 'Français', flag: '🇫🇷' },
  { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
]

function LandingPage() {
  const [scrollY, setScrollY] = useState(0)
  const [activeSection, setActiveSection] = useState('inicio')
  const [activeService, setActiveService] = useState(0)
  const [stats, setStats] = useState([
    { number: 0, target: 376, label: 'Usuarios registrados', suffix: '+' },
    { number: 0, target: 5780, label: 'Reservas creadas', suffix: '+' },
    { number: 0, target: 99, label: 'Automatización', suffix: '%' }
  ])
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null)
  const statsRef = useRef(null)
  const animationStarted = useRef(false)

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY)
      const sections = ['inicio', 'servicios', 'contacto']
      const currentSection = sections.find(section => {
        const element = document.getElementById(section)
        if (element) {
          const rect = element.getBoundingClientRect()
          return rect.top <= 100 && rect.bottom > 100
        }
        return false
      })
      if (currentSection) {
        setActiveSection(currentSection)
      }

      if (statsRef.current && !animationStarted.current) {
        const rect = statsRef.current.getBoundingClientRect()
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          animationStarted.current = true
          animateStats()
        }
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveService((prev) => (prev + 1) % services.length)
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  const animateStats = () => {
    let frame = 0
    const totalFrames = 100

    const counter = setInterval(() => {
      frame++
      setStats(currentStats =>
        currentStats.map(stat => ({
          ...stat,
          number: Math.min(Math.round((stat.target / totalFrames) * frame), stat.target)
        }))
      )

      if (frame === totalFrames) {
        clearInterval(counter)
      }
    }, 20)
  }

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
    setActiveSection(sectionId)
    setMobileMenuOpen(false)
  }

  const handleLanguageClick = (event) => {
    setLanguageAnchorEl(event.currentTarget)
  }

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null)
  }

  const services = [
    {
      title: 'Reservas Hoteleras',
      image: `${hotelImg}`
    },
    {
      title: 'Reservas para turnos de servicios',
      image: `${img2}`
    },
    {
      title: 'Compartir online tu calendario',
      image: `${img3}`
    },
    {
      title: 'Gestión inteligente de lista de espera',
   image: `${waitingImg}`
    },
    {
      title: 'Comunicación y notificaciones con tus clientes en tiempo real',
   image: `${notificationImg}`
    }
  ]

  const plans = [
    {
      name: 'Básico',
      price: '$9.99',
      features: ['100 reservas/mes', 'Soporte por email', 'Calendario básico']
    },
    {
      name: 'Pro',
      price: '$29.99',
      features: ['Reservas ilimitadas', 'Soporte prioritario', 'Calendario avanzado', 'Integración con WhatsApp'],
      recommended: true,
      savings: '$10/mes'
    },
    {
      name: 'Empresarial',
      price: 'Contactar',
      features: ['Solución personalizada', 'Soporte 24/7', 'API completa', 'Capacitación personalizada']
    }
  ]

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ height: '100vh', overflowY: 'auto', width: '100%' }}>
        <AppBar position="fixed" color="transparent" elevation={0} sx={{ backdropFilter: 'blur(8px)', width: '100%' }}>
          <Box
            sx={{
              height: '4px',
              background: 'linear-gradient(to right, #ef4444, #f59e0b, #3b82f6)',
              backgroundSize: '200% 100%',
              animation: 'gradient 5s ease infinite',
              width: '100%',
              '@keyframes gradient': {
                '0%': { backgroundPosition: '0% 50%' },
                '50%': { backgroundPosition: '100% 50%' },
                '100%': { backgroundPosition: '0% 50%' },
              },
            }}
          />
          <Container>
            <Toolbar sx={{ justifyContent: 'space-between', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <Link href="/timeline" underline="none" sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                          src={logo}

                          style={{ width: '8%', height: '8%', objectFit: 'cover', borderRadius: '6px', marginRight: '4px' }}
            />
                <Typography variant="h6" component="div" sx={{ fontWeight: 300 }}>
                  EReservas
                </Typography>
              </Link>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  {['inicio', 'servicios',  'contacto'].map((section) => (
                    <Button
                      key={section}
                      onClick={() => scrollToSection(section)}
                      sx={{
                        color: 'text.primary',
                        backgroundColor: 'transparent',
                        position: 'relative',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          width: '100%',
                          height: '2px',
                          backgroundColor: 'primary.main',
                          transform: activeSection === section ? 'scaleX(1)' : 'scaleX(0)',
                          transition: 'transform 0.3s ease-in-out',
                        }
                      }}
                    >
                      {section.toUpperCase()}
                    </Button>
                  ))}
                </Box>
                <Box sx={{ height: '24px', width: '1px', bgcolor: 'grey.300', mx: 2 }} />
                <IconButton
                  onClick={handleLanguageClick}
                  sx={{ mr: 1 }}
                >
                  <Globe />
                </IconButton>
                <Menu
                  anchorEl={languageAnchorEl}
                  open={Boolean(languageAnchorEl)}
                  onClose={handleLanguageClose}
                >
                  {languages.map((lang) => (
                    <MenuItem key={lang.code} onClick={handleLanguageClose}>
                      <span style={{ marginRight: '8px' }}>{lang.flag}</span>
                      {lang.name}
                    </MenuItem>
                  ))}
                </Menu>
                <IconButton
                  href="/login"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    borderRadius: '50%',
                    border: '1px solid',
                    borderColor: 'grey.300',
                  }}
                >
                  <User />
                </IconButton>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  sx={{ display: { md: 'none' }, ml: 1 }}
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>

        <Drawer
          anchor="right"
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
        >
          <Box sx={{ width: 250 }} role="presentation">
            <List>
              {['inicio', 'servicios',  'contacto'].map((section) => (
                <ListItem button key={section} onClick={() => scrollToSection(section)}>
                  <ListItemText primary={section.toUpperCase()} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1 }}>
          <Box
            id="inicio"
            sx={{
              minHeight: '100vh',
              display: 'flex',
              alignItems: 'center',
              pt: 8,
              backgroundImage: `url(${backgroundImage})`,
              backgroundAttachment: 'fixed',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <Container>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Typography variant="h2" gutterBottom>
                    Simplifica tus reservas con un diseño minimalista
                  </Typography>
                  <Typography variant="h3" color="text.secondary" paragraph>
                    Una plataforma moderna para gestionar reservas de manera eficiente y elegante.
                  </Typography>
                  <Typography variant="body1" color="text.secondary" paragraph>
                    Representando nuestra misión de digitalizar y simplificar el proceso de reservas.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
  <Box
    sx={{
      display: 'inline-block',
      borderRadius: '50px', // Borde completamente redondeado
      padding: '2px', // Espacio para el borde degradado
      background: 'linear-gradient(45deg, #ef4444, #f59e0b, #3b82f6)',
      marginTop: '460px',
      marginRight: '80px'
    }}
  >
    <Button
      variant="contained"
      size="large"
      onClick={() => {
        const servicesSection = document.getElementById('servicios')
        if (servicesSection) {
          servicesSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }}
      sx={{
        
        borderRadius: '50px',
        backgroundColor: 'white',
        color: 'text.primary',
        padding: '10px 20px',
        minWidth: '200px',
        transition: 'all 0.3s ease',
        boxShadow: '0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08)',

      }}
    >
      Descubre Nuestros Servicios
    </Button>
  </Box>
</Grid>
              </Grid>
            </Container>
          </Box>

          <Box id="servicios" sx={{ py: 8, bgcolor: 'background.paper' }}>
            <Container>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h2" gutterBottom>
                    Nuestros Servicios
                  </Typography>
                  <Box sx={{ mt: 4 }}>
                    {services.map((service, index) => (
                      <Box
                        key={index}
                        sx={{
                          cursor: 'pointer',
                          mb: 3,
                          '&:hover': { color: 'primary.main' },
                        }}
                        onClick={() => setActiveService(index)}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 300,
                            color: activeService === index ? 'primary.main' : 'inherit',
                          }}
                        >
                          {service.title}
                        </Typography>
                        <Box
                          sx={{
                            height: '1px',
                            width: '100%',
                            mt: 1,
                            bgcolor: activeService === index ? 'primary.main' : 'grey.300',
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ position: 'relative', height: { xs: '300px', sm: '600px' }, overflow: 'hidden' }}>
                    {services.map((service, index) => (
                      <Box
                        key={service.title}
                        sx={{
                          position: 'absolute',
                          inset: 0,
                          transition: 'all 1s ease-in-out',
                          opacity: activeService === index ? 1 : 0,
                          transform: activeService === index ? 'translateX(0)' : 'translateX(100%)',
                        }}
                      >
                        <img
                          src={service.image}
                          alt={service.title}
                          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box
            ref={statsRef}
            sx={{ py: 8 }}
            onClick={animateStats}
          >
            <Container>
              <Grid container spacing={4}>
                {stats.map((stat, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="h2" component="div">
                        {stat.number}{stat.suffix}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {stat.label}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>

          {/* <Box id="planes" sx={{ py: 8, bgcolor: 'background.paper', position: 'relative', overflow: 'hidden' }}>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 0,
                opacity: 0.1,
                backgroundImage: 'url("/placeholder.svg?height=600&width=800&text=Calendarios+3D")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                animation: 'float 20s ease-in-out infinite',
                '@keyframes float': {
                  '0%, 100%': { transform: 'translateY(0) rotate(0deg)' },
                  '50%': { transform: 'translateY(-30px) rotate(5deg)' },
                },
              }}
            />
            <Container sx={{ position: 'relative', zIndex: 1 }}>
              <Typography variant="h2" align="center" gutterBottom>
                Planes y Precios
              </Typography>
              <Grid container spacing={4} sx={{ mt: 4 }}>
                {plans.map((plan, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'white',
                        boxShadow: index === 1
                          ? 'none'
                          : '0 4px 6px rgba(0,0,0,0.1)',
                        transform: index === 1 ? 'scale(1.05)' : 'none',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          transform: index === 1 ? 'scale(1.07)' : 'translateY(-10px)',
                          boxShadow: index === 1
                            ? 'none'
                            : '0 20px 30px rgba(0, 0, 0, 0.1)',
                        },
                        ...(index === 1 && {
                          position: 'relative',
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: -2,
                            left: -2,
                            right: -2,
                            bottom: -2,
                            // background: 'linear-gradient(45deg, #ef4444, #f59e0b, #3b82f6)',
                            backgroundSize: '200% 200%',
                            animation: 'gradientBorder 5s ease infinite',
                            zIndex: -1,
                            borderRadius: '8px',
                          },
                          '@keyframes gradientBorder': {
                            '0%': { backgroundPosition: '0% 50%' },
                            '50%': { backgroundPosition: '100% 50%' },
                            '100%': { backgroundPosition: '0% 50%' },
                          },
                        }),
                      }}
                    >
                      {plan.recommended && (
                        <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 1, textAlign: 'center' }}>
                          <Typography variant="subtitle2">Más Recomendado</Typography>
                        </Box>
                      )}
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography variant="h5" component="div" gutterBottom>
                          {plan.name}
                        </Typography>
                        <Typography variant="h3" component="div" sx={{ my: 2 }}>
                          {plan.price}
                        </Typography>
                        <List>
                          {plan.features.map((feature, featureIndex) => (
                            <ListItem key={featureIndex} disablePadding>
                              <ListItemIcon>
                                <Calendar color="primary" size={20} />
                              </ListItemIcon>
                              <ListItemText primary={feature} />
                            </ListItem>
                          ))}
                        </List>
                        {plan.savings && (
                          <Typography variant="subtitle1" color="success.main" sx={{ mt: 2 }}>
                            Ahorras {plan.savings}
                          </Typography>
                        )}
                      </CardContent>
                      <CardActions>
                        <Button fullWidth variant={plan.recommended ? 'contained' : 'outlined'} sx={{ borderWidth: '1px' }}>
                          Elegir Plan
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box> */}

          <Box id="contacto" sx={{ py: 8 }}>
            <Container>
              <Typography variant="h2" align="center" gutterBottom>
                Contacto
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
     
                  
                  <Link href="https://wa.me/qr/PO6OGPRKU2Q2A1" target="_blank" rel="noopener noreferrer" variant="h5" gutterBottom >Contáctanos por WhatsApp</Link>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 , mt: 8}}>

                  <img
                    src={WhatsAppQR}
                    alt="WhatsApp QR Code"
                    width={200}
                    height={200}
                  />
                  
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>

        <Box component="footer" sx={{ py: 3, bgcolor: 'background.paper', borderTop: 1, borderColor: 'divider' }}>
          <Container>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  © 2024 EReservas. Todos los derechos reservados.
                </Typography>
              </Grid>
              <Grid item>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Link href="#" color="inherit">Política de Privacidad</Link>
                  <Link href="#" color="inherit">Términos de Servicio</Link>
                  <Link href="#" color="inherit">Soporte</Link>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {[Facebook, Twitter, Instagram].map((Icon, index) => (
                    <IconButton key={index} color="inherit" aria-label="social media">
                      <Icon size={20} />
                    </IconButton>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default LandingPage;