import React, { useState } from 'react';
import { Button, TextField, Grid, Box, Typography, Container, IconButton, Link, useTheme } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import loginImg from '../../assets/character-ereservas.png';
import logo from '../../assets/e-reservas-logo.png';
import { useAuth } from '../../hooks/useAuth'; // Importar el contexto de autenticación

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const { login, loginWithGoogle } = useAuth();

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle();
      navigate('/timeline');
    } catch (error) {
      console.error(error);
      setError('Error al iniciar sesión con Google.');
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    // console.log('Usuario autenticado:', user);
    try {
      await login(email, password);
      navigate('/timeline');
    } catch (error) {
      console.error(error);
      setError('Error al iniciar sesión. Verifica tus credenciales.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        minHeight: '100vh',
        background: 'linear-gradient(to right, #eff4ff, #34a8ff)',
      }}
    >
      <Container component="main" maxWidth="sm" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '20px',
            padding: '40px',
            boxShadow: '-16px 20px 6px 9px rgba(0, 0, 0, 0.2)',
            width: '100%',
          }}
        >
          <Link href="/" underline="none" sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={logo}

              style={{ width: '8%', height: '8%', objectFit: 'cover', borderRadius: '6px', marginRight: '4px' }}
            />
            <Typography variant="h6" component="div" sx={{ fontWeight: 300 }}>
              EReservas
            </Typography>
          </Link>
          <Typography variant="h5" sx={{ mb: 3 }}>
            Bienvenido 👋
          </Typography>

          <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: '10px',
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: '10px',
              }}
            />
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
              <Typography />
              <Link href="#" variant="body2" sx={{ color: theme.palette.primary.main }}>
                Forgot Password?
              </Link>
            </Box>
            {error && (
              <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
                borderRadius: '30px',
                padding: '10px 20px',
              }}
            >
              LOGIN
            </Button>

            <Typography variant="body2" align="center" sx={{ mt: 2, mb: 1 }}>
              or continue with
            </Typography>
            <Grid container justifyContent="center">
              <IconButton sx={{ backgroundColor: '#E0E0E0', margin: '0 10px' }} onClick={handleGoogleLogin}>
                <GoogleIcon />
              </IconButton>
            </Grid>
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              Don't have an account yet?{' '}
              <Link href="/signup" variant="body2" sx={{ color: theme.palette.primary.main }}>
                Sign up for free
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>

      <Box
        component="img"
        src={loginImg}
        alt="Illustration"
        sx={{
          width: { xs: '100%', sm: '50%' },
          maxHeight: '100vh',
          objectFit: 'cover',
          borderTopRightRadius: '20px',
          borderBottomRightRadius: '20px',
          display: { xs: 'none', sm: 'block' }
        }}
      />
    </Box>
  );
};

export default LoginPage;