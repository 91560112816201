// src/utils/dateUtils.js
import { CheckCircle, HourglassEmpty, Cancel } from '@mui/icons-material';

// Funciones utilitarias para manejo de fechas
export const getCurrentHour = () => new Date();

// Función para obtener el color según el estado
export const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case 'reservado':
      return '#3f51b5'; // Azul
    case 'confirmado':
      return '#4caf50'; // Verde
    case 'cancelado':
      return '#f44336'; // Rojo
    default:
      return '#9e9e9e'; // Gris
  }
};

// Función para obtener el ícono según el estado
export const getStatusIcon = (status) => {
  switch (status.toLowerCase()) {
    case 'reservado':
      return <HourglassEmpty />;
    case 'confirmado':
      return <CheckCircle />;
    case 'cancelado':
      return <Cancel />;
    default:
      return <CheckCircle />;
  }
};