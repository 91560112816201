import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerOffIcon from '@mui/icons-material/PowerSettingsNew'; // Importar el ícono de Power Off
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth'
import logo from '../../assets/e-reservas-logo.png';

const NavbarContainer = styled(Box)(({ theme }) => ({
  width: '3%', // Ancho ajustado al 5%
  height: '100vh', // Altura total
  backgroundColor: 'black', // Fondo negro
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'fixed', // Fijo a la izquierda
  top: 0,
  left: 0,
  zIndex: 1000, // Asegúrate de que esté por encima del contenido
}));

const NavIconButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(1),
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderRadius: '10px',
  },
}));

const Navbar = () => {
  const navigate = useNavigate();
  const { logout} = useAuth();

  const handleReservationsClick = () => {
    navigate('/timeline'); // Ruta principal para reservas
  };

  const handleRoomsClick = () => {
    navigate('/rooms'); // Ruta para ABM de habitaciones
  };

  const handleLogoutClick = async () => {
    try {
      await logout(); // Cerrar sesión con Firebase
      console.log('Sesión cerrada'); // Mensaje de éxito (opcional)
      navigate('/login'); // Redirigir a la página de login
    } catch (error) {
      console.error('Error al cerrar sesión: ', error); // Manejar el error
    }
  };

  return (
    <NavbarContainer>
      <Box>
        {/* Iconos de Navegación */}
        <Tooltip title="Reservas" placement="right">
          <NavIconButton onClick={handleReservationsClick}>
            {/* <CalendarTodayIcon fontSize="small" /> */}
            <img
                          src={logo}

                          style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '15%' }}
            />
          </NavIconButton>
        </Tooltip>
        <Tooltip title="Gestión de Habitaciones" placement="right">
          <NavIconButton onClick={handleRoomsClick}>
            <SettingsIcon fontSize="small" />
          </NavIconButton>
        </Tooltip>
      </Box>

      <Box>
        {/* Icono de Power Off para cerrar sesión */}
        <Tooltip title="Cerrar Sesión" placement="right">
          <NavIconButton onClick={handleLogoutClick}>
            <PowerOffIcon fontSize="medium" />
          </NavIconButton>
        </Tooltip>
      </Box>
    </NavbarContainer>
  );
};

export default Navbar;