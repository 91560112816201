/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useRef } from "react";
import Timeline from "react-timelines";
import { buildTimebar, buildTrack } from "./builders";
import "react-timelines/lib/css/style.css";
import { fill } from "./utils";
import ReservationModal from '../ReservationModal'; // Asegúrate de importar tu modal
import { saveReservation } from '../../api/reservations';
import {useAuth} from '../../hooks/useAuth'

const now = new Date();

// Habitaciones
let tracksById = {};

const NUM_OF_YEARS = 2;
const START_YEAR = now.getFullYear();

const timebar = buildTimebar(START_YEAR, NUM_OF_YEARS);


const MIN_ZOOM = 2;
const MAX_ZOOM = 200;



const TimelineComponent = (props) => {
  const [tracks, setTracks] = useState([]);
  const { reservations, rooms } = props;
  const [open, setOpen] = useState(false);
  const [zoom, setZoom] = useState(200);
  const {currentUser} = useAuth();

  const timelineRef = useRef(null); // Referencia al contenedor del timeline

  // Estado para el modal de reserva
  const [modalOpen, setModalOpen] = useState(false);
  const [reservation, setReservation] = useState({}); // Estado para la reserva actual



  useEffect(() => {
    if (rooms?.length > 0) {
      let fullRooms = rooms.concat(

        { id: 1, title: ' 1' },
        { id: 2, title: ' 1' },
        { id: 3, title: ' 1' },
        { id: 4, title: ' 1' },
        { id: 5, title: ' 1' },
        { id: 6, title: ' 1' },
        { id: 7, title: ' 1' },
        { id: 8, title: ' 1' },
        { id: 9, title: ' 1' },
        { id: 10, title: ' 1' },
       

      );
      const tracksById = fill(fullRooms.length).reduce((acc, i) => {
        const track = buildTrack(fullRooms[i], i, reservations);
        acc[track.id] = track;
        return acc;
      }, {});
      setTracks(Object.values(tracksById).reverse());
    }
  }, [rooms]);

  const handleToggleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 1, MAX_ZOOM));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 1, MIN_ZOOM));
  };

  const handleToggleTrackOpen = (track) => {
    setTracks((prevTracks) => {
      const updatedTracks = {
        ...tracksById,
        [track.id]: {
          ...track,
          isOpen: !track.isOpen,
        },
      };
      return Object.values(updatedTracks);
    });
  };

  const start = new Date(`${START_YEAR}`)
  const end = new Date(`${START_YEAR + NUM_OF_YEARS}`)


  const handleOnSaveReservation = (newReservation) => {
    // Lógica para guardar la reserva
    console.log('Guardando reserva:', newReservation);
    saveReservation({
      user: currentUser,
      reservation: newReservation
    });
  }



  // Manejar el desplazamiento del timeline
  const handleScroll = () => {
    const scrollPosition = timelineRef.current.scrollLeft; // Obtiene la posición de desplazamiento
    console.log(`Scroll position: ${scrollPosition}px`); // Registra la posición de desplazamiento
  };
  const handleReservationData = (e) => {
    const { name, value } = e.target;

    setReservation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleModalOpen = (open) => {
    setReservation({});
    setModalOpen(open);
  }
  const handleReservationClick = (reservationData) => {
    const { id } = reservationData;
    let reservationSelected = reservations.find((reservation) => reservation.id === id);
    setReservation(reservationSelected); // Cargar la reserva seleccionada
    setModalOpen(true); // Abrir el modal
  }

  return (
    <div
      className="app"
      onDoubleClick={() => handleModalOpen(true)}
      style={{ height: '100vh', overflow: 'hidden' }} // Asegúrate de que el contenedor ocupe el alto total
    >
      <div
       
        style={{ overflowX: "none", whiteSpace: "nowrap", height: "100%" }} // Asegúrate de que el contenedor del timeline sea desplazable
        onScroll={handleScroll} // Añadir evento de desplazamiento
      >
        <Timeline
        ref={timelineRef}
          scale={{
            start,
            end,
            zoom,
            zoomMin: MIN_ZOOM,
            zoomMax: MAX_ZOOM,
          }}
          isOpen={open}
          toggleOpen={handleToggleOpen}
          zoomIn={handleZoomIn}
          zoomOut={handleZoomOut}
          clickElement={handleReservationClick}
          clickTrackButton={(track) => {
            console.log(JSON.stringify(track));
          }}
          timebar={timebar}
          tracks={tracks}
          now={now}
          toggleTrackOpen={handleToggleTrackOpen}
          enableSticky={true}
          scrollToNow={true}
          useAsGrid={true} 
        />
      </div>

      {/* Modal de reserva */}
      <ReservationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)} // Manejar el cierre del modal
        onSave={handleOnSaveReservation} // Lógica para guardar la reserva
        rooms={rooms} // Pasa la lista de habitaciones
        reservation={reservation} // Pasa la reserva actual
        handleInputChange={handleReservationData}
      />
    </div>
  );
};

export default TimelineComponent;