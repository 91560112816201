//src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme.js';
import App from './App';

const root = document.getElementById('root');
ReactDOM.createRoot(root).render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);