var NOW = new Date();
export const START_YEAR = NOW.getFullYear();
export const NUM_OF_YEARS = 2;
export const MONTH_NAMES = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];
export const MONTHS_PER_YEAR = 12;
export const QUARTERS_PER_YEAR = 4;
export const MONTHS_PER_QUARTER = 3;
export const NUM_OF_MONTHS = NUM_OF_YEARS * MONTHS_PER_YEAR;
export const MAX_TRACK_START_GAP = 2;
export const MAX_ELEMENT_GAP = 3;
export const MAX_MONTH_SPAN = 2;
export const MIN_MONTH_SPAN = 2;
export const NUM_OF_TRACKS = 2;
export const MAX_NUM_OF_SUBTRACKS = 0;
