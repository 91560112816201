// src/components/TimelinePage.jsx
import React, { useState } from 'react';
import { Grid, AppBar, Toolbar, Typography, IconButton, TextField } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import TimelineComponent from './Timeline/TimelineComponent';

const TimelinePage = ({
  selectedDate,
  rooms,
  bookings,
  onElementClick,
}) => {



  return (
    <Grid container spacing={2} sx={{ height: '100%', overflow: 'hidden' }}>
      <Grid item xs={12} sx={{ height: '100vh', width: '100%' }}>
        <AppBar position="static" sx={{ backgroundColor: 'white', borderBottom: '1px solid #e4e4e4' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="gray" component="div" sx={{ flexGrow: 1 }}>
              Disponibilidad
            </Typography>
          </Toolbar>
        </AppBar>
        <TimelineComponent
          reservations={bookings}
          onElementClick={onElementClick}
          selectedDate={selectedDate}
          rooms={rooms}
        />
      </Grid>
    </Grid>
  );
};

export default TimelinePage;