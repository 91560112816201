// src/components/RoomManagement/RoomManagement.jsx
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RoomsAdapter from '../../api/rooms'; // Ruta correcta

const RoomManagement = ({ rooms, setRooms }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentRoom, setCurrentRoom] = useState({ id: '', name: '' });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchRooms();
    // eslint-disable-next-line
  }, []);

  const fetchRooms = async () => {
    try {
      const fetchedRooms = await RoomsAdapter.getAllRooms();
      setRooms(fetchedRooms);
    } catch (error) {
      console.error('Error al obtener las habitaciones:', error);
    }
  };

  const handleOpenDialog = (room = { id: '', name: '' }) => {
    setCurrentRoom(room);
    setIsEditing(!!room.id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCurrentRoom({ id: '', name: '' });
    setIsEditing(false);
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentRoom(prev => ({ ...prev, [name]: value }));
  };

  const handleSaveRoom = async () => {
    if (!currentRoom.name.trim()) {
      alert('El nombre de la habitación es obligatorio.');
      return;
    }

    try {
      if (isEditing) {
        await RoomsAdapter.updateRoom(currentRoom.id, { name: currentRoom.name });
        console.log('Habitación actualizada');
      } else {
        await RoomsAdapter.createRoom({ name: currentRoom.name });
        console.log('Habitación creada');
      }
      fetchRooms();
      handleCloseDialog();
    } catch (error) {
      console.error('Error al guardar la habitación:', error);
    }
  };

  const handleDeleteRoom = async (id) => {
    if (window.confirm('¿Estás seguro de eliminar esta habitación?')) {
      try {
        await RoomsAdapter.deleteRoom(id);
        console.log('Habitación eliminada');
        fetchRooms();
      } catch (error) {
        console.error('Error al eliminar la habitación:', error);
      }
    }
  };

  return (
    <Box
      sx={{
        padding: '20px',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        height: '100%',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Gestión de Habitaciones
      </Typography>
      <Button variant="contained" color="primary" onClick={() => handleOpenDialog()} sx={{ marginBottom: '20px' }}>
        Agregar Habitación
      </Button>
      {rooms.length === 0 ? (
        <Typography variant="body1">No hay habitaciones registradas.</Typography>
      ) : (
        rooms.map((room) => (
          <Box key={room.id} sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', padding: '10px', border: '1px solid #e0e0e0', borderRadius: '5px' }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {room.name}
            </Typography>
            <IconButton color="primary" onClick={() => handleOpenDialog(room)}>
              <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDeleteRoom(room.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))
      )}

      {/* Diálogo para Agregar/Editar Habitación */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{isEditing ? 'Editar Habitación' : 'Agregar Habitación'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre de la Habitación"
            name="name"
            fullWidth
            variant="standard"
            value={currentRoom.name}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={handleSaveRoom} variant="contained" color="primary">
            {isEditing ? 'Actualizar' : 'Agregar'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RoomManagement;