// src/api/reservations.js
import { 
  collection, 
  addDoc, 
  getDocs, 
  query, 
  where, 
  Timestamp, 
  onSnapshot ,
  getDoc,
  updateDoc,
  doc
} from 'firebase/firestore';
import {db, auth} from './firebase'


const checkUser = () => {
  const uid = auth.currentUser?.uid;
  if (!uid) throw new Error('Usuario no autenticado');
}
/**
 * Obtener reservas que se solapan con un rango de fechas.
 * @param {Date} startDate - Inicio del rango.
 * @param {Date} endDate - Fin del rango.
 * @returns {Promise<Array>} Array de reservas que se solapan con el rango.
 */
export const getReservationsByRange = async (userID, startDate, endDate) => {
  try {
    const reservationsRef = collection(db, 'reservations');
    checkUser();

    // Crear la consulta para obtener solo las reservas del usuario autenticado
    // y que solapen con el rango de fechas especificado
    const qQuery = query(
      reservationsRef,
      where('userId', '==', userID),
    );

    const querySnapshot = await getDocs(qQuery);
    console.log('Número de reservas encontradas:', querySnapshot.size);

    const reservations = [];
    querySnapshot.forEach((doc) => {
      console.log(`Reserva encontrada: ID = ${doc.id}`);
      reservations.push({ id: doc.id, ...doc.data() });
    });
    return reservations;
  } catch (error) {
    console.error('Error al obtener reservas por rango: ', error);
    return [];
  }
};

/**
 * Guardar una nueva reserva.
 * @param {Object} reservation - Objeto de reserva.
 * @returns {Promise<Object>} Referencia del documento creado.
 */
export const saveReservation = async (payload) => {
  try {
    const { user, reservation } = payload;
    const {id, ...data} = reservation;
    checkUser();

    const {uid} = user;
    console.log("usuario", uid);
    const reservationData = {
      ...data,
      userId: uid, // Agregar el uid del usuario
    };

    if (id) {
      const docRef = doc(db, 'reservations', id);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        await updateDoc(docRef, reservationData);
        console.log('Reserva actualizada con ID: ', id);
      }
    } else {
      const newDocRef = await addDoc(collection(db, 'reservations'), reservationData);
      console.log('Reserva creada con ID: ', newDocRef.id);
      return newDocRef;
    }
  } catch (error) {
    console.error('Error al guardar la reserva: ', error);
    throw error;
  }
};
/**
 * Obtener todas las reservas sin filtros.
 * @returns {Promise<Array>} Array de todas las reservas.
 */
export const getAllReservations = async () => {
  checkUser();
  try {
    const reservations = [];
    const querySnapshot = await getDocs(collection(db, 'reservations'));
    querySnapshot.forEach((doc) => {

      reservations.push({ id: doc.id, ...doc.data() });
    });
    return reservations;
  } catch (error) {
    console.error('Error al obtener todas las reservas: ', error);
    return [];
  }
};

/**
 * Escuchar en tiempo real las reservas que se solapan con un rango de fechas.
 * @param {Date} startDate - Inicio del rango.
 * @param {Date} endDate - Fin del rango.
 * @param {Function} callback - Función a llamar con las reservas actualizadas.
 * @returns {Function} Función para desuscribirse del listener.
 */
export const listenReservationsByRange = (startDate, endDate, callback) => {
  const reservationsRef = collection(db, 'reservations');

  const qQuery = query(
    reservationsRef,
    where('start', '<=', Timestamp.fromDate(endDate)),
    where('end', '>=', Timestamp.fromDate(startDate))
  );

  const unsubscribe = onSnapshot(
    qQuery,
    (querySnapshot) => {
      const reservations = [];
      querySnapshot.forEach((doc) => {
        reservations.push({ id: doc.id, ...doc.data() });
      });
      callback(reservations);
    },
    (error) => {
      console.error('Error en el listener de reservas: ', error);
    }
  );

  return unsubscribe;
};

/**
 * Obtener reservas dentro de un rango de un mes atrás y un mes adelante desde una fecha de referencia.
 * @param {Date} [referenceDate=new Date()] - Fecha de referencia para calcular el rango.
 * @returns {Promise<Array>} Array de reservas dentro del rango especificado.
 */
export const getReservations = async (userID, referenceDate = new Date()) => {

  try {
    const startDate = new Date(referenceDate);
    startDate.setMonth(startDate.getMonth() - 6); // Un mes atrás
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(referenceDate);
    endDate.setMonth(endDate.getMonth() + 12); // Un mes adelante
    endDate.setHours(23, 59, 59, 999);

    console.log(`Obteniendo reservas desde ${startDate.toISOString()} hasta ${endDate.toISOString()}`);

    const reservations = await getReservationsByRange(userID,startDate, endDate);
    return reservations;
  } catch (error) {
    console.error('Error al obtener reservas en el rango de un mes: ', error);
    return [];
  }
};