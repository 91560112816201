"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _computedStyle = _interopRequireDefault(require("./computedStyle"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var _default = exports["default"] = function _default(node, prop) {
  return parseInt((0, _computedStyle["default"])(node).getPropertyValue(prop), 10);
};